import React from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import {device, deviceMax} from "../helpers/devicesizes";
import {navigate} from 'gatsby'; 

const StyledBox = styled(Box)`
    @media ${device.tablet} {
        padding:100px;
    }
    @media ${deviceMax.tablet} {
        padding:50px 20px;
    }
`;

const StyledCard = styled(Card)`
    text-align:center;
    @media ${device.tablet} {   
        padding: 100px;
    }
    @media ${deviceMax.tablet} {
        padding: 50px 20px;
    }
`;

const StyledButton = styled(Button)`
`;

const CaseNotFoundPage = props => {
  const onClick = (event) => {
    navigate("/");
  }
  return (
    <StyledBox display="flex" justifyContent="center" alignItems="center">
      <StyledCard>
        <Typography variant="body2" style={{color:"#3C7FFC", paddingBottom:'80px'}}>Antamillasi tunnistetiedoilla ei löydy yhtään palautetta.</Typography>
        <StyledButton variant="contained" color="primary"onClick={onClick}>OK</StyledButton>
      </StyledCard>
    </StyledBox>
  );
}

export default CaseNotFoundPage;