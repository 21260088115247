import React from "react"
import { Link } from "gatsby-theme-material-ui";
import Typography from '@material-ui/core/Typography';
import NononoHandSVG from '../../svg-assets/nonono-logo-hand.svg';
import NononoLogoSVG from '../../svg-assets/nonono-logo3.svg';
import Box  from "@material-ui/core/Box";
import styled, { keyframes } from 'styled-components';
import {device, deviceMax} from "../helpers/devicesizes";

const NoNoNoKeyframes = keyframes`
  13% {transform:rotateZ(25deg);}
  26% {transform:rotateZ(-25deg);}
  39% {transform:rotateZ(25deg);}
  52% {transform:rotateZ(-25deg);}
  65% {transform:rotateZ(25deg);}
  78% {transform:rotateZ(-25deg);}
  91% {transform:rotateZ(25deg);}
  100% {transform:rotateZ(0deg);}
`;

const NononoHand = styled(NononoHandSVG)`
  height: 35px;
  margin-bottom: 3px;
  &:hover {
    animation: ${NoNoNoKeyframes} 1.5s linear 1;       
  }
`;

const NononoLogo = styled(NononoLogoSVG)`
  height: 20px;
  margin: 10px;
  path.st0{fill:#0d3c79}
  path.st2{fill:#0d3c79}
`;

const HeaderBox = styled(Box)`
  background-color: #fff;
  height: 48px;
`;
const HeaderInnerBox = styled(Box)`
  @media ${device.tablet} {
    padding:5px 60px;
  }
  @media ${deviceMax.tablet} {
    padding:5px 10px;
  }
`
const BoxLink = styled(Link)`
  @media ${device.tablet} {
    padding: 0px 30px;
  }
  @media ${deviceMax.tablet} {
    padding: 0px 10px;
  }
  display: inline-block;
`;
export default function Header() {
    return (
        <HeaderBox>
          <HeaderInnerBox display="flex" justifyContent="center" alignItems="center">
          <Box flexGrow={1}>
            <Link to='/'><NononoHand /><NononoLogo /></Link>
          </Box>
          </HeaderInnerBox>
        </HeaderBox>
    )
  }