import React from "react";
import Layout from "../../components/layout";
import SimpleLayout from "../../components/simple-layout";
import CaseNotFoundPage from "../../components/casenotfound";
import AuthReviewCaseCard from "../../components/auth-review-casecard";
import CircularProgress from '@material-ui/core/CircularProgress';
import Axios from "axios";
import { graphql } from "gatsby";

const ReviewLayout = ({ data, id }) => {
    //console.log(data)
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [token, setToken] = React.useState(id);
    const [feedback, setFeedback] = React.useState(null);
    const [storageData, setStorageData] = React.useState(null);
    React.useEffect(() => {
        console.log(token)
        setLoading(true);
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
        Axios.get(data.site.siteMetadata.serverUrl + "/reviewtoken/" + token +"/", {headers: headers})  
        .then(function (response) {
            if(response.data.status === 'error') {
                console.log(response)
                setError(true);
            }
            else {
                // 1. Set case info
                const slug = response.data.data.slug;
                const d = {token:token, slug: slug}
                localStorage.setItem('nononodata', JSON.stringify(d));
                setStorageData(d);
                setFeedback(response.data.data);
            }
            setLoading(false);
        }) 
        .catch(function (error) {
            console.log(error);
            setError(true);
            setLoading(false);
        });
    }, [token]);

  
  // Let's try authenticate with token
  // 1. Jos token  on virheellinen/vanhentunut -> näytetään virhelaatikko
  if(error) {
    return <Layout children={<CaseNotFoundPage />} />
  }
  // 2. muutoin ohjataan review näkymään
  else if(feedback) {
    return <SimpleLayout children={<AuthReviewCaseCard feedback={feedback} serverUrl={data.site.siteMetadata.serverUrl} userData={storageData} />} />
  }
  return <Layout children={<CircularProgress />} />
}

export default ReviewLayout;

export const query = graphql`
  query ReviewQuery {
    site {
      siteMetadata {
        siteUrl
        serverUrl
        title
        description
      }
    }
  }
`