import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Rating from '@material-ui/lab/Rating';
import Card from '@material-ui/core/Card';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import {device, deviceMax} from "../helpers/devicesizes";
import Axios from "axios";
import Link from "gatsby-link";
import {useStyles, CssTextField} from './styles/global';
import Box from '@material-ui/core/Box';
import Pagination from '@material-ui/lab/Pagination';
import { PaginationItem } from '@material-ui/lab';

const StyledCard = styled(Card)`
    max-width: 616px;
    @media ${device.tablet} {
        margin: 50px auto;
    }
    @media ${deviceMax.tablet} {
        margin: 30px 10px;
    }
    padding: 12px;
`;
const StyledThumbUpOutlinedIcon = styled(ThumbUpOutlinedIcon)`
    font-size: 40px;
    &:hover {
        cursor: pointer;
    }
`;

const StyledThumbDownOutlinedIcon = styled(ThumbDownOutlinedIcon)`
    font-size: 40px;
    &:hover {
        cursor: pointer;
    }
`;
const StyledThumbUpIcon = styled(ThumbUpIcon)`
    font-size: 40px;
    &:hover {
        cursor: pointer;
    }
`;

const StyledThumbDownIcon = styled(ThumbDownIcon)`
    font-size: 40px;
    &:hover {
        cursor: pointer;
    }
`;




export default function AuthReviewCaseCard(props) {
    const classes = useStyles();
    const initialValues = {
        id: props.feedback.slug,
        reviewmessage: '',
        rating: 0,
        wdba: null,
        nps: null,
        token: props.userData.token,
    };
    const [state, setState] = React.useState(initialValues);
    const [error, setError] = React.useState(false);
    const [succeed, setSucceed] = React.useState(false);
    const thumbIcons = [];
    if(state.wdba===null) {
        thumbIcons.push(<React.Fragment key={"fragment1"}><StyledThumbUpOutlinedIcon name="yes" onClick={()=>{toggleWDBA('true')}} key={"thumb1"} size="large" style={{color:'#3C7FFC'}} /><StyledThumbDownOutlinedIcon name="no" onClick={()=>{toggleWDBA('false')}} key={"thumb4"} size="large" style={{margin: '5px 0 0 10px', color:'#CC3300'}} /></React.Fragment>);
    }
    else if(!state.wdba) {
        thumbIcons.push(<React.Fragment key={"fragment2"}><StyledThumbUpOutlinedIcon name="yes" onClick={()=>{toggleWDBA('true')}} key={"thumb2"} size="large" style={{color:'#3C7FFC'}} /><StyledThumbDownIcon name="no" onClick={()=>{toggleWDBA('false')}} key={"thumb5"} size="large" style={{margin: '5px 0 0 10px', color:'#CC3300'}} /></React.Fragment>);
    }
    else if(state.wdba) {
        thumbIcons.push(<React.Fragment key={"fragment3"}><StyledThumbUpIcon name="yes" onClick={()=>{toggleWDBA('true')}} key={"thumb3"} size="large" style={{color:'#3C7FFC'}} /><StyledThumbDownOutlinedIcon name="no" onClick={()=>{toggleWDBA('false')}} key={"thumb6"} size="large" style={{margin: '5px 0 0 10px', color:'#CC3300'}} /></React.Fragment>);
    }

    const getSteps =() => {
        return ['Arvostele Matkahuollon palvelu', 'Tyytyväisyys Matkahuoltoon', 'Käytätkö jatkossa Matkahuollon palveluita?', 'Kuinka todennäköisesti suosittelisit Matkahuoltoa kaverillesi tai kollegallesi?'];
    }

    const npsChange = (e) => {
        setState({ ...state, ['nps']: Number(e.target.innerText) });
    }

    const renderPaginationItem = (item) => {
        const newItem = item;
        newItem.page = newItem.page -1; 
        return <PaginationItem {...newItem}  />
    }

    const getStepContent = (step) => {
          switch (step) {
              case 0:
                  return <CssTextField 
                      rows={4} 
                      rowsMax={50} 
                      fullWidth={true} 
                      variant="outlined" 
                      value={state.reviewmessage} 
                      onChange={handleChange} 
                      multiline={true}
                      label={"Julkinen arvostelu"}
                      id="reviewmessage" 
                      name="reviewmessage" 
                      required={true} 
                      helperText={"Kirjoita vähintään 10 merkkiä."}
                  />;
              case 1:
                  return <Rating size="large" value={state.rating} style={{fontSize:'40px'}} name="rating" onChange={handleRatingChange} />;
              case 2:
                  return thumbIcons;
              case 3: 
                return (
                <>
                <Pagination count={11} hidePrevButton hideNextButton variant="outlined" boundaryCount={11} defaultPage={-1} shape="rounded" onChange={npsChange} renderItem={renderPaginationItem} />
                <div style={{margin:'10px 0'}}>
                <div>0 = Ei ollenkaan todennäköinen suosittelu</div>
                <div>10 = Erittäin todennäköinen suosittelu</div>
                </div>
                </>
                )
              default:
                  return 'Vaihetta ei löydy';
          }
      }

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if(activeStep === steps.length - 1)
            handleReviewSubmit();
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReviewSubmit = () => {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
        Axios.post(props.serverUrl + "/review/", JSON.stringify(state), {headers: headers})  
        .then(function (response) {
            //console.log(response);
            if(response.data.status === 'error') {
                setSucceed(false);
                setError(true);
            }
            else {
                setError(false);
                setSucceed(true);
                setState(initialValues);
            }
        }) 
        .catch(function (error) {
            console.log(error);
            setError(true);
        });
    };
    const handleRatingChange = (event, newValue) => {
        setState({ ...state, ['rating']: newValue });
    };

    const handleChange = (event) => {
        const { target: { name, value } } = event;
        let handleValue = value;

        setState({ ...state, [name]: handleValue });
    };

    const toggleWDBA = (value) => {
        let tmp = {...state };
        if(value == 'true') {
            tmp['wdba'] = true;
        }
        else {
            tmp['wdba'] = false;
        }
        setState( tmp );
    };
    const validInput = (activeStep) => {
        switch (activeStep) {
            case 0:
                return state.reviewmessage && state.reviewmessage.length >= 10;
            case 1:
                return state.rating && state.rating != 0;
            case 2:
                return !(state.wdba == null);
            case 3: 
                return !(state.nps == null);
            default:
                return false;
        }
    }

  return (
    <StyledCard>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <div>{getStepContent(index)}</div>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                    variant="contained"
                    color="primary"
                  >
                    Edellinen
                  </Button>
                  <Button
                    disabled={!validInput(activeStep)}
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? 'Lähetä' : 'Seuraava'}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography variant="body1" style={{textAlign:'center'}}>Kaikki valmista - Kiitos arvostelusta!</Typography>
          <Typography variant="body2" style={{textAlign:'center'}}><Link to={"/palaute/"+state.id}>Näytä palaute</Link></Typography>
        </Paper>
      )}
    </StyledCard>
  );
}
